'use client';

import { connect, ConnectedProps } from 'react-redux';
import { RootState } from '../../../lib/store/Store';
import { AppOperations } from '../../../lib/store/operations/App.operations';
import { Header } from './Header';

const mapStateToProps = (state: RootState) => ({
	videoPlaying: state.app.videoPlaying,
});

const mapDispatchToProps = {
	setVideoPlaying: AppOperations.setVideoPlaying,
};

const connector = connect(mapStateToProps, mapDispatchToProps);
const HeaderConnected = connector(Header);

export type HeaderReduxProps = ConnectedProps<typeof connector>;
export { HeaderConnected as Header };
