import { type Dispatch } from '@reduxjs/toolkit';
import { appSlice, AppState } from '../slices/App.slice';

export const AppOperations = {
	setVideoPlaying: (playing: AppState['videoPlaying']) => (dispatch: Dispatch) => {
		dispatch(appSlice.actions.setVideoPlaying(playing));
	},
	setAuth0Token: (token: AppState['auth0Token']) => (dispatch: Dispatch) => {
		dispatch(appSlice.actions.setAuth0Token(token));
	},
	setUserInfo: (userInfo: AppState['userInfo']) => (dispatch: Dispatch) => {
		dispatch(appSlice.actions.setUserInfo(userInfo));
	},
	setUserRewardsInfo: (userRewardsInfo: AppState['userRewardsInfo']) => (dispatch: Dispatch) => {
		dispatch(appSlice.actions.setUserRewardsInfo(userRewardsInfo));
	},
	resetApp: () => (dispatch: Dispatch) => {
		dispatch(appSlice.actions.resetApp());
	},
};
