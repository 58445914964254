'use client';

import React, { useEffect, useRef, useState } from 'react';
import { IconClose } from '@maverick/icons/dist/IconClose';
import { DiagonalArrowIcon } from '@maverick/icons/dist/DiagonalArrowIcon';
import Link from 'next/link';
import { Icon } from '../../../../ui/Icon';

import styles from './HomeMenu.module.scss';
import { BrandsLogos } from '../../../../../shared/constants/BrandsLogos';
import { useTrapFocus } from '../../../../../shared/hooks/useTrapFocus';
import { GaEvent } from '@maverick/utils';
import { useRouter } from 'next/navigation';
interface HomeMenuProps {
	setDrawerOpen: (open: boolean) => void;
}

const HomeMenu = ({ setDrawerOpen }: HomeMenuProps) => {
	const router = useRouter();
	const [currentPath, setCurrentPath] = useState<string>('/');
	const homeMenu = useRef(null);

	useTrapFocus(homeMenu);

	const handleHomePageButtonClick = () => {
		GaEvent.HomepageButtonClick('Homepage link');
	};

	useEffect(() => {
		setCurrentPath(window.location.pathname);
	}, [window.location.pathname]);

	const handleHashLink = (hash: string) => {
		if (!window.location.href.includes('terms-and-conditions')) {
			router.push(`/terms-and-conditions#${hash}`);
		} else {
			window.location.hash = `#${hash}`;
		}
	};

	const handleCloseMenu = () => {
		setDrawerOpen(false);
	};

	const handleKeyDown = (ref: string) => (ev: React.KeyboardEvent) => {
		if (ev.key == 'Enter') {
			handleSublinksClick(ref);
		}
	};

	const handleSublinksClick = (ref: string, ev?: React.MouseEvent) => {
		handleHashLink(ref);
		handleCloseMenu();
	};

	const HOME_HREF = '/';
	const FAQS_HREF = '/faqs';
	const MISSING_POINTS_HREF = '/add-a-visit-verify';
	const PRIVACY_POLICY = 'privacy-policy';
	const ACCESSIBILITY_STATEMENT = 'accessibility';

	return (
		<div className={styles['drawer']} ref={homeMenu}>
			<div className={styles['top-drawer']}>
				<div>
					<div className={styles['top']}>
						<button
							data-testid='close-button'
							aria-label='Close side menu'
							className={styles['close']}
							onClick={() => setDrawerOpen(false)}
							tabIndex={0}
						>
							<Icon icon={IconClose} customSize />
						</button>
					</div>
					<div className={styles['content']}>
						<ul className={styles['links']}>
							<li style={currentPath === HOME_HREF ? { color: '#F9D949' } : {}}>
								<Link
									tabIndex={0}
									data-testid='home-button'
									onClick={() => {
										currentPath === HOME_HREF && handleCloseMenu();
										handleHomePageButtonClick();
									}}
									href={HOME_HREF}
								>
									Home
								</Link>
							</li>
							<li style={currentPath === FAQS_HREF ? { color: '#F9D949' } : {}}>
								<Link
									tabIndex={0}
									data-testid='faqs-button'
									onClick={() => currentPath === FAQS_HREF && handleCloseMenu()}
									href={FAQS_HREF}
								>
									FAQs
								</Link>
							</li>
							<li style={currentPath === MISSING_POINTS_HREF ? { color: '#F9D949' } : {}}>
								<Link
									tabIndex={0}
									data-testid='missingPoints-button'
									href={MISSING_POINTS_HREF}
									aria-label='Missing Points'
								>
									Missing Points
								</Link>
							</li>
						</ul>
					</div>
					<div className={styles['border']} />
					<div className={styles['sublinks']}>
						<ul className={styles['links']}>
							<li id='privacy-&-notices' data-testid='privacy-&-notices'>
								<div
									onClick={(ev) => handleSublinksClick(PRIVACY_POLICY, ev)}
									onKeyDown={handleKeyDown(PRIVACY_POLICY)}
									tabIndex={0}
								>
									Privacy & Legal Notices
								</div>
							</li>
							<li id='acessibility-statment' data-testid='acessibility-statment'>
								<div
									onClick={(ev) => handleSublinksClick(ACCESSIBILITY_STATEMENT, ev)}
									onKeyDown={handleKeyDown(ACCESSIBILITY_STATEMENT)}
									tabIndex={0}
								>
									Accessibility Statement
								</div>
							</li>
						</ul>
					</div>
				</div>
				<div
					className={styles['bottom-drawer']}
					tabIndex={0}
					id='rights-reserved'
					data-testid='rights-reserved'
				>
					© {new Date().getFullYear()} Bloomin' Brands, Inc. All Rights Reserved.
				</div>
			</div>
			<section className={styles['brand-section']}>
				<Link
					tabIndex={0}
					data-testid='outback-button'
					aria-label='Outback Steakhouse Reservations'
					className={`${styles['brand-button']} ${styles['outback-button']}`}
					href='https://www.outback.com/seating'
				>
					<div className={styles['brand-button-arrow']}>
						<Icon icon={DiagonalArrowIcon} customSize />
					</div>
					<img src={BrandsLogos.outback.white.src} alt={BrandsLogos.outback.white.alt} />
				</Link>
				<Link
					tabIndex={0}
					data-testid='carrabbas-button'
					aria-label='Carrabba’s Italian Grill Reservations'
					className={`${styles['brand-button']} ${styles['carrabbas-button']}`}
					href='https://www.carrabbas.com/seating'
				>
					<div className={styles['brand-button-arrow']}>
						<Icon icon={DiagonalArrowIcon} customSize />
					</div>
					<img src={BrandsLogos.carrabbas.white.src} alt={BrandsLogos.carrabbas.white.alt} />
				</Link>
				<Link
					tabIndex={0}
					data-testid='bonefish-button'
					aria-label='Bonefish Grill Reservations'
					className={`${styles['brand-button']} ${styles['bonefish-button']}`}
					href='https://www.bonefishgrill.com/reservations'
				>
					<div className={styles['brand-button-arrow']}>
						<Icon icon={DiagonalArrowIcon} customSize />
					</div>
					<img src={BrandsLogos.bonefish.white.src} alt={BrandsLogos.bonefish.white.alt} />
				</Link>
				<Link
					tabIndex={0} 
					data-testid='flemings-button'
					aria-label='Fleming’s Prime Steakhouse & Wine Bar Reservations'
					className={`${styles['brand-button']} ${styles['flemings-button']}`}
					href='https://www.flemingssteakhouse.com/reservations'
				>
					<div className={styles['brand-button-arrow']}>
						<Icon icon={DiagonalArrowIcon} customSize />
					</div>
					<img src={BrandsLogos.flemings.white.src} alt={BrandsLogos.flemings.white.alt} />
				</Link>
			</section>
		</div>
	);
};

export default HomeMenu;
