interface Logo {
	white: {
		src: string;
		alt: string;
	};
	black: {
		src: string;
		alt: string;
	};
	colorful: {
		src: string;
		alt: string;
	};
}

interface BrandsLogos {
	outback: Logo;
	bonefish: Logo;
	carrabbas: Logo;
	flemings: Logo;
	express: Logo;
	dineRewards: Logo;
}

export const BrandsLogos: BrandsLogos = {
	outback: {
		white: {
			src: 'https://edge.sitecorecloud.io/osi-60501bcc/media/Project/BBI/shared/Dine-Rewards/outback-logo-white.svg',
			alt: "Outback's Logo",
		},
		black: {
			src: 'https://edge.sitecorecloud.io/osi-60501bcc/media/Project/BBI/shared/Dine-Rewards/outback-logo-black.svg',
			alt: "Outback's Logo",
		},
		colorful: {
			src: 'https://edge.sitecorecloud.io/osi-60501bcc/media/Project/BBI/shared/Dine-Rewards/outback-logo.svg',
			alt: "Outback's Logo",
		},
	},
	bonefish: {
		white: {
			src: 'https://edge.sitecorecloud.io/osi-60501bcc/media/Project/BBI/shared/Dine-Rewards/bonefish-logo-white.svg',
			alt: "Bonefish Grill's Logo",
		},
		black: {
			src: 'https://edge.sitecorecloud.io/osi-60501bcc/media/Project/BBI/shared/Dine-Rewards/bonefish-logo-black.svg',
			alt: "Bonefish Grill's Logo",
		},
		colorful: {
			src: 'https://edge.sitecorecloud.io/osi-60501bcc/media/Project/BBI/shared/Dine-Rewards/bonefishgrill-logo.svg',
			alt: "Bonefish Grill's Logo",
		},
	},
	carrabbas: {
		white: {
			src: 'https://edge.sitecorecloud.io/osi-60501bcc/media/Project/BBI/shared/Dine-Rewards/carrabbas-logo-white.svg',
			alt: "Carrabbas's Logo",
		},
		black: {
			src: 'https://edge.sitecorecloud.io/osi-60501bcc/media/Project/BBI/dine-rewards/logos/carrabbas-logo-black.svg',
			alt: "Carrabbas's Logo",
		},
		colorful: {
			src: 'https://edge.sitecorecloud.io/osi-60501bcc/media/Project/BBI/shared/Dine-Rewards/carrabbas-logo.svg',
			alt: "Carrabbas's Logo",
		},
	},
	flemings: {
		white: {
			src: 'https://edge.sitecorecloud.io/osi-60501bcc/media/Project/BBI/shared/Dine-Rewards/flemings-logo-white.svg',
			alt: "Flemings's Logo",
		},
		black: {
			src: 'https://edge.sitecorecloud.io/osi-60501bcc/media/Project/BBI/shared/Dine-Rewards/flemings-logo-black.svg',
			alt: "Flemings's Logo",
		},
		colorful: {
			src: 'https://edge.sitecorecloud.io/osi-60501bcc/media/Project/BBI/shared/Dine-Rewards/flemings-logo.svg',
			alt: "Flemings's Logo",
		},
	},
	express: {
		white: {
			src: 'https://edge.sitecorecloud.io/osi-60501bcc/media/Project/BBI/shared/Dine-Rewards/express-logo.svg',
			alt: "Express's Logo",
		},
		black: {
			src: 'https://edge.sitecorecloud.io/osi-60501bcc/media/Project/BBI/shared/Dine-Rewards/express-logo-black.svg',
			alt: "Express's Logo",
		},
		colorful: {
			src: 'https://edge.sitecorecloud.io/osi-60501bcc/media/Project/BBI/shared/Dine-Rewards/express-logo.svg',
			alt: "Express's Logo",
		},
	},
	dineRewards: {
		white: {
			src: 'https://edge.sitecorecloud.io/osi-60501bcc/media/Project/BBI/shared/Dine-Rewards/dine-rewards-logo-white.svg',
			alt: 'Dine Rewards Logo',
		},
		black: {
			src: '',
			alt: 'Dine Rewards Logo',
		},
		colorful: {
			src: 'https://edge.sitecorecloud.io/osi-60501bcc/media/Project/BBI/shared/Dine-Rewards/dine-rewards-logo.svg',
			alt: 'Dine Rewards Logo',
		},
	},
};
