export const IconPlayCircle = (
	<g
		viewBox='0 0 24 24'
		fill='none'
		stroke='currentColor'
		strokeWidth='2'
		strokeLinecap='round'
		strokeLinejoin='round'
	>
		<circle cx='12' cy='12' r='10'></circle>
		<polygon points='10 8 16 12 10 16 10 8'></polygon>
	</g>
);
